//#eb6e1f
$headerCTAmargin: 200px;
$topNavPlaceholder: 137;
#topNav .theme-nav-style-dropdown > .theme-nav-item.hasChild > .theme-nav-link:before{
  display: none;
}
#topNav .theme-main-nav .theme-nav-style-dropdown > .theme-nav-item.selected, #topNav .theme-main-nav .theme-nav-style-dropdown > .theme-nav-item.dropdown-open{
  border-bottom: 5px solid #000;
}
#topNav.topNav.layout-1.has-site-logo .theme-nav-style-dropdown, #topNav.topNav.layout-2.has-site-logo .theme-nav-style-dropdown{
  margin-right: $headerCTAmargin;
}
#topNav.topNav .theme-main-nav{
  margin-top: 75px;
}
#topNav.topNav.layout-1 #sn-site-logo, #topNav.topNav.layout-2 #sn-site-logo{
  top:35px;
}
#topNav.topNav .headerCallToAction{
  top:-40px;
  right:5px;
  position: absolute;;
}
//
.homeMediaSlider .mediaSlider .flexslider .slideTextWrapper:before{
  top:0px;
}
#topNav.topNav .headerCallToAction a{
  max-width: $headerCTAmargin;
}
body #topNavPlaceholder{
  height: calc( #{$topNavPlaceholder} * 1px) !important;
  border-bottom: 0px !important;
}
.has-sub-nav body #topNavPlaceholder{
  height: calc((  #{$topNavPlaceholder} + 40  )*1px) !important;
}
html{
  #topNav.topNav{
    .theme-main-nav{
      transition: 300ms margin ease-in-out;
    }
    #sn-site-logo{
      transition: 300ms height ease-in-out;
    }
    .headerCallToAction{
      transition: 300ms top ease-in-out;
    }
  }
  &.nav-fixed{
    #topNav.topNav{
      .theme-main-nav{
        margin-top: 25px;
      }
      #sn-site-logo{
        height: 50px;
      }
      .headerCallToAction{
        top: -15px;
      }
    }
  }
}
