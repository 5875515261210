html.nav-fixed {
  padding-bottom: 0;
}
html.nav-fixed .site-background {
  bottom: 0;
}
a {
  color: #eb6e1f;
}
.pageOptions, .extendedOptions {
  padding-top: 5px;
  padding-bottom: 5px;
}
.textBlockElement h3,
.eventAggregatorElement .aggHeader,
.aggHeader {
  max-width: initial;
  width: 100%;
  border: none;
  box-shadow: none;
  background: transparent;
  color: #eb6e1f;
  padding-left:0;
}
// news aggregators
.newsAggregatorElement .item {
  border-top: none;
  padding-top: 15px;
  padding-bottom: 0;
}
.newsAggregatorElement .item .newsItemElement,
.newsAggregatorElement .details {
  border-bottom: 1px solid lightgray;
  padding-bottom: 15px;
}
.yieldPageContent .pageElement .item.extendedOptions {
  padding-top:5px;
  padding-bottom:5px;
}
.newsAggregatorElement .articleHasImage .newsItemHeader > a {
  position: relative;
}
.newsAggregatorElement .item a > img {
  max-width: 200px;
}
.newsAggregatorElement .expanded .newsItemHeader h2,
.newsAggregatorElement .details {
  padding-top: 2%;
}
.newsAggregatorElement .expanded .newsItemHeader h2,
.newsAggregatorElement .item .details h4, .item > ul h4,
.eventAggregatorElement .summary {
  font-size: 18px;
  font-weight: normal;
}
.newsAggregatorElement .newsAuthor, .newsAggregatorElement .newsDate {
  font-size: 13px;
  padding: 5px 0;
}
.expanded .newsItemElement h4,
.newsAggregatorElement .item .teaser {
  font-size: 16px;
  text-transform: initial;
}
.newsAggregatorElement .articleHasImage > h4,
.newsAggregatorElement .articleHasImage .newsText,
.newsAggregatorElement .articleHasImage .commentsAndMore,
.newsAggregatorElement .articleHasImage .newsItemHeader {
  padding-left:0;
}
.eventAggregatorElement .vevent ~ .vevent {
  border:none;
}
.eventAggregatorElement .dateImage {
  background: white;
  padding: 0;
}
.eventAggregatorElement .dateImage:after {
  content: none;
}
.eventAggregatorElement .dateImage .month {
  background: #000000;
  color: white;
  padding: 10px 0;
}
.eventAggregatorElement .dateImage .date {
  color: #eb6e1f;
  padding: 5px 0;
}
// read more button
.siteContainer [class^="button-"] {
  border-bottom: none;
  padding: 5px 25px;
  text-align: left;
  font-weight: normal;
  position:relative;
}
.siteContainer [class^="button-"]:after {
  content:'';
  display: inline-block;
  position: relative;
  margin-left: 5px;
  border-left: 5px solid #000000;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  transition: transform 200ms ease-out;
}
.linkElement .goToLink:after,
.linkElement .emailLink:after {
  content: '';
  display: inline-block;
  position: relative;
  margin-left: 5px;
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  border-left: 5px solid #000000;
}
.linkElement h4 a {
  text-align: center;
  font-weight: normal;
  box-shadow: none;
  background-color: #eb6e1f
}
/*custom banner*/
html.has-main-nav:not(.has-sub-nav) #siteHeader {
  margin-top: 0;
}
#siteHeader.customBannerStyle {
  h1 {
    font-weight: bold;
    font-size: 40px;
    color: white;
    display: inline-block;
    position: relative;
    padding-bottom: 10px;
    &:after {
      content: '';
      position: absolute;
      width: 50%;
      width: 50%;
      border-bottom: 2px solid white;
      left: 0;
      bottom: 0;
      margin: 0 25%;
    }
  }
  .site-banner-wrapper {
    max-height: initial;
    height: 200px;
    min-height: 200px;
    max-width: 100%;
    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: #eb6e1f;
      mix-blend-mode: color;
    }
  }
  .home & {
    display: none;
  }
}
